import {FC} from 'react';
import {useSelector} from 'react-redux';
import RegistrationForm from './RegistrationForm';
import RegistrationTitle from './RegistrationTitle';
import Socials from '../SocialsProviders';
import {RootState} from '../../../../../state/store';
import {IUserRegisterState} from '../../../../../state/Auth/auth.types';

const Registration: FC = (): JSX.Element => {
  const {isAuthenticated} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );

  if (isAuthenticated) {
    return (
      <div style={{textAlign: 'center'}}>
        Almost there! Kindly follow the link we&prime;ve sent you via email to
        finalize the registration process.
      </div>
    );
  }

  return (
    <div className='form-container form-container--registration'>
      <RegistrationTitle />
      <RegistrationForm />
      <Socials pageTitle='registration' />
    </div>
  );
};

export default Registration;
