import {FC} from 'react';
import LoginForm from './LoginForm';
import LoginTitle from './LoginTitle';
import SocialsProviders from '../SocialsProviders';

const Login: FC = (): JSX.Element => (
  <div className='form-container'>
    <LoginTitle />
    <LoginForm />
    <SocialsProviders pageTitle='login' />
  </div>
);

export default Login;
